import React, {useEffect, useState} from 'react'
import {Paper, Table, TableBody, TableHead, TableFooter, TableRow, TablePagination} from "@material-ui/core";
import {CompanyDto,countCompanies,findCompanies} from "../../api/CompanyApi";
import CompanyPreview from "./CompanyPreview";
import {navigate} from "gatsby";

import TableHeaderCellStyled from "../common/TableHeaderCellStyled";
import ButtonStyled from "../common/ButtonStyled";
import SearchStyled from "../common/SearchStyled";

import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';

interface CompaniesSearchProps{
    style?:any
}

export default function CompaniesSearch(props: CompaniesSearchProps){
    const classes = useStyles();
    const { t } = useTranslation();

    const [totalCompaniesCount, setTotalCompaniesCount] = useState<number>(0);
    const [rows, setRows] = useState<CompanyDto[]>([]);
    const [rowsPerPageLimit, setRowsPerPageLimit] = useState<number>(10);
    const [currentPageOffset, setCurrentPageOffset] = useState<number>(0);
    const [filterByNameContaining, setFilterByNameContaining] = useState("")

    const getCurrentRows = (rowsPerPage, currentPage, nameContaining) => {
        findCompanies(rowsPerPage, currentPage*rowsPerPage, nameContaining).then(data => {
            setRows(data)
        }).catch(err => console.log("Error getting company rows ",err))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var limit = parseInt(event.target.value, 10)
        getCurrentRows(limit, 0, filterByNameContaining)
        setRowsPerPageLimit(limit);
        setCurrentPageOffset(0)
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        getCurrentRows(rowsPerPageLimit, newPage, filterByNameContaining)
        setCurrentPageOffset(newPage)
    };

    const handleFilterByName = (value: string) => {
        getCurrentRows(rowsPerPageLimit, currentPageOffset, value)
        countCompanies(value).then(data => {
            setTotalCompaniesCount(data)
        }).catch(err => console.log("Error getting total companies count ",err))
        setFilterByNameContaining(value)
    }

    const columns = [
        { key: 'id', label: t("ID"), width: "5%" },
        { key: 'logo', label: t("LOGO"), width: "30%" },
        { key: 'name', label: t("COMPANYNAME"), width: "65%"},
    ];

    useEffect(() => {
        countCompanies(filterByNameContaining).then(data => {
            setTotalCompaniesCount(data)
            getCurrentRows(rowsPerPageLimit, currentPageOffset, filterByNameContaining)
        }).catch(err => console.log("Error getting total companies count ",err))
    },[]);

    return (
        <Paper className={classes.root} style={props.style}>
            <div className={classes.filterContainer}>
                <SearchStyled text={t("SEARCHCOMPANYNAME")} value={filterByNameContaining} handleValueFunction={handleFilterByName}/>
                <ButtonStyled text={t("CREATENEW")} onClick={() => navigate("/companies/create")}/>
            </div>
            <Table aria-label={t("COMPANYSEARCHTABLE")}>
                <TableHead>
                    <TableRow key="companies-search-header">
                        {columns.map((column) => (
                            <TableHeaderCellStyled key={column.key} width={column.width} label={column.label}/>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <CompanyPreview key={row.id+row.name} id={row.id} name={row.name} logo={""}/>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow key="companies-search-footer">
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30, { label: t("ALL"), value: 9999 }]}
                            colSpan={3}
                            count={totalCompaniesCount}
                            rowsPerPage={rowsPerPageLimit}
                            page={currentPageOffset}
                            SelectProps={{
                                inputProps: { 'aria-label': t("ROWSPERPAGE") },
                                native: true,
                            }}
                            labelRowsPerPage={t("ROWSPERPAGE")}
                            labelDisplayedRows={({ from, to, count }) =>from + '-' + to + t("ROWSOF") + count}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    )
}
