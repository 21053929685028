import React from 'react';
import Layout from '../../components/Layout';

import CompaniesSearch from "../../components/companies/CompaniesSearch";

export default function Companies() {

    return (
        <Layout allowAnonymous={false}>
            <CompaniesSearch style={{minWidth:700, width:"65%", marginLeft: "auto", marginRight: "auto"}}/>
        </Layout>
    )
}