import React from 'react'
import {TableCell, TableRow} from "@material-ui/core";
import {navigate} from "gatsby";

import { useStyles } from "../../styles/TableStyles";

interface CompanyPreviewProps{
    id: number,
    name: string,
    logo: string,
}
export default function CompanyPreview( props: CompanyPreviewProps){
    const classes = useStyles();

    return (
        <TableRow onClick={()=>navigate("/companies/"+props.id)} className={classes.childRowWithLink} hover>
            <TableCell align="left">
                {props.id}
            </TableCell>
            <TableCell align="left">
                {props.logo}
            </TableCell>
            <TableCell align="left">
                {props.name}
            </TableCell>
        </TableRow>
    )
}